$primaryColor: #229fe1;
$primaryBgColor: #33B0F2;
$topBarHeight: 50px;
$sideNavWidth: 220px;

@-webkit-keyframes slide{from{background-position:0 0}to{background-position:-4000px 0}}
@keyframes slide{from{background-position:0 0}to{background-position:-4000px 0}}

body{
  @media (min-width:1400px){ &{ zoom:111% } }
  @media (min-width:1600px){ &{ zoom:125% } }
  @media (min-width:1800px){ &{ zoom:150% } }
  @media (min-width:2000px){ &{ zoom:190% } }
  @media (min-width:2200px){ &{ zoom:195% } }
  @media (min-width:2500px){ &{ zoom:200% } }
  @media (min-width:3000px){ &{ zoom:260% } }
}

a{ 
  color:$primaryColor; text-decoration:underline; cursor:pointer; 
  &:hover{ color:#000; text-decoration:none; }
}

h1,h2,h3,h4{ color:#222 }
p{ color:#444; padding-top:0; margin-top:0 }

.hidden{ display:none; }
.ps .ps__rail-x:hover, .ps .ps__rail-y:hover, .ps .ps__rail-x:focus, .ps .ps__rail-y:focus, .ps .ps__rail-x.ps--clicking, .ps .ps__rail-y.ps--clicking{ background-color:transparent; opacity: 0.9; }
.phone-dropdown-btn{ background-color:none !important; border:none !important }

.bg-white{ background:#FFF }

.site-logo{
  display:flex; align-items:center; justify-content:center; height:$topBarHeight; min-height:$topBarHeight; background-color: $primaryBgColor; 
  box-shadow:0px 5px 5px -3px rgba(0, 0, 0, 0.06),0px 8px 10px 1px rgba(0, 0, 0, 0.042),0px 3px 14px 2px rgba(0, 0, 0, 0.036);
  & .logo{ display:flex; align-items:center; }
  & .md{ display:block; &.md-compact{ display:none } }
}

.logo_box{
  display:flex; align-items:center;  & img{ height:28px; };
  @media (min-width:600px){ &:not(.sm){min-width: 150px; } }
  @media (max-width:900px){ & img{ height:25px } }
  @media (max-width:600px){ & img{ height:22px } }
}

.site-footer{
  padding-bottom:25px; background:none; width: 100%; display: flex; align-items: center; justify-content:space-between; font-size:13px; gap:8px; line-height: 14px;
  & .cr{ color:#333 };
  & .links{ 
    display:flex; gap: 15px;
    & a{ text-decoration:underline; :hover{ text-decoration:none } }
  };
  &.sf-s{ padding-bottom:0; flex-direction: column-reverse; margin-bottom: -5px; margin-top: 15px;  font-size: 12px;
  }
  @media (max-width:1200px){ &.sf-dashboard{ font-size:11px; line-height:12px; } }
  @media (max-width:600px){ &{ flex-direction:column; gap:5px; padding-bottom:15px } }
}

.box-loading{
  width: 100%; height: calc(100vh - 50px); display: flex; align-items:center; justify-content:center;
  & img{ width:auto; height: 24px };
  & .circleProgress{ position: absolute; left: -9px; right: 0; top: calc(50% - 23px); };
  & > div{ position: relative; }
}

.page-content{ border-radius:5px; background:rgba(255,255,255,0.96); padding:30px;  box-shadow: 0 0 7px #ccc; 
  & h1{ margin:0 0 15px 0; padding:0; color:#222; font-size:24px; line-height:26px }
  & h4{ margin:0 0 5px 0; padding:0; color:#222; font-size:16px;  line-height:18px }
  & p{ margin:0 0 10px 0; padding:0;
    &.underline {  padding-top:10px; text-decoration:underline; }
  }
}

.page-not-found{ text-align:center; & svg{ font-size:100px; color:#222; margin-top: 0px; margin-bottom:10px; } }

.btn-search{ padding:9px; color:#FFF; @media (max-width:600px){ &{ position:absolute; right:212px} } };

.search-overlay{ background:rgba(0,0,0,0.3); width:100%; height:100%; left: 0; right:0; top:0; bottom:0;position:fixed; z-index:99; };

.search-box{
  z-index: 100; display: flex;align-items: center; padding-left:12px; background:#FFF; color:#222; position: relative; top:0; left: 5px; width:48%;padding-right:2px; border-radius: 4px;
  height: calc(#{$topBarHeight} - 14px); 
  &.mb{ position:absolute; top:$topBarHeight; left:0; width:100%; padding-right:6px; height:$topBarHeight; border-radius: 0; }
  &::placeholder{ color: #666 };
  & .MuiCircularProgress-root{ margin:7px; display:flex };
  & .items{ background:#FFF; position:absolute; top:41px; left:0; right:0; box-shadow:0 0px 5px #ddd; border-radius:4px; overflow:hidden; };
  & .MuiButtonBase-root{ width:34px };
  & .MuiCircularProgress-root + .MuiButtonBase-root{ margin-left:-4px };
  & .fld{ width:100%; border:none; outline:none; font-size:15px; padding-left:6px; padding-right:10px; height: calc(100% - 1px); border-radius:10px; background:none; color:#222; margin-bottom: 1px; z-index:10; &::placeholder{ color:#666 };
  };
  & .item{
    padding:10px 12px 8px; background:#FFF; gap:12px; border:0; border-bottom:1px solid #eee;
    & .pht{ cursor:pointer; width:40px; height:40px; padding:1px; border:1px solid #ddd; border-radius:50%; display:flex };
    & .dtl{ display:flex; flex:1; flex-direction:column; width:calc(100% - 70px); justify-content: center;
      & a{ margin-bottom:2px; text-decoration:none; font-size:13px; font-weight:bold; line-height: 16px; overflow:hidden; white-space: nowrap; text-overflow: ellipsis; width: 100%; display: block };
      & .ln{ margin:0; overflow:hidden; font-size:12.1px; white-space: nowrap; text-overflow: ellipsis; width: 100%; display:block; gap:4px; line-height:18px;
        & .fa{ text-align:center; font-size: 14px; width:14px; margin-right:2px };
      };
      & .dot{ display: inline-flex; background: #888; width: 4px;height: 4px; border-radius:50%; margin: -1px 7px 0 7px };
      & .MuiSkeleton-root{ border-radius:5px }
    };
    &.ld{ & .dtl{ & a{ margin-bottom:3px }; & .ln{ display:flex; padding-top:5px } } }
  }
  @media (max-width:767px){ &{ width:42%; } }
  @media (min-width:601px){ & .items { maxHeight: 300px }  }
  @media (max-width:600px){
    &{ box-shadow:0px 5px 5px -3px rgba(0, 0, 0, 0.06),0px 8px 10px 1px rgba(0, 0, 0, 0.042),0px 3px 14px 2px rgba(0, 0, 0, 0.036);
    & .items{ border-radius:0; top:$topBarHeight; box-shadow:0 0px 2px #ddd; height: calc(100vh - $topBarHeight - $topBarHeight) }
    };
    &.mb{ width:100% }
  };
}

.notifcation-btn{
  padding:9px;margin-right:4px;
  & .fa, .far{ color:#fff; font-size: 17px; width:19px };
  & .MuiBadge-badge{ background: #d00; color: #fff; box-shadow: none; width: 18px; height: 18px; min-height: 18px; min-width: 18px; font-size: 11px; };
  @media (max-width:600px){
    &{ margin-right: 0; }; 
  }
}

.notifcation-drawer{
  width:280px; position:relative; overflow:hidden;
  & .close-btn{ 
    color:#666; position:absolute; cursor:pointer; z-index:2; right:0; width: 48px; align-items:center; justify-content:center; display:flex; height: $topBarHeight;
    & .fa,.far{ font-size: 18px };
  };
  & .header{ gap:8px; padding: 16px; display: flex; align-items: center; height: $topBarHeight; background:#f5f5f5; position:relative; z-index:1; box-shadow: 0 0 3px #ccc;
    & .fa, .far{ font-size:18px; };
    & h5{ margin-left: 8px; margin-top: 0; margin-bottom: 0; font-weight: 600; font-size:15px; };
    & h5 span{ font-weight: 400; font-size:13px};
  };
  & .view-more{ background:#f5f5f5; font-weight:bold; text-align:center; width:100%; font-size: 13px; line-height: 14px; box-shadow: 0 0 2px #ccc }
}

h2.content-heading{
  &, > .MuiContainer-root{font-weight:500; color: #222; line-height:18px; margin-left:0;margin-top:0;margin-right:0; display:flex; align-items:center; z-index:1; }
  & .icon{ margin-top:-1px };
  & .cntr{ color: #666; font-weight:400; margin-left:5px };
  & div{ position:absolute; right:16px; font-weight:normal };
}

.form-box{  
  
  & .field{ flex-direction:row; justify-content:space-between; align-items:flex-start;
    &.cl{ flex-direction:column; }
  };
  & .field-control{ 
    flex:1; width:100% ; flex-direction:column; align-items: flex-start;
    &.inline{ flex-direction:row; align-items:center; gap:6px; };
  }
  & .label{ 
      font-weight:bold; font-size:15px; color:#555; transform:none; position:static; margin-left: 0; margin-right: 0;
    &.inline{ &.before{ margin-left: 0; } &:not(.before){ margin-right: 10px; } };
  };
  & .lbl-radio{ margin-bottom: -5px }
  & .icon{ margin-top:-4px; width:16px; text-align:center; font-size: 16px !important; color: #777;
    &.ierr{ color:#d00; }
  }
  & .ferror{ 
      color:#e00; font-size:13px; margin-top:6px; display:flex; font-weight:bold; line-height:14px; 
      & .fa, .far{ font-size:14px; margin-right:5px; line-height: 15px; margin-bottom: -1px; } 
  };
  & .fld_text{ width:100%; & input{ padding:7px 0 9px } };
  & .fld_select{ 
    width:100%; margin:0px !important; 
    & em{ color:#aaa }; 
    &::before{ border-color:#d5d5d5 };
    & .MuiSelect-select{ padding-top:7px; padding-bottom:9px; background: transparent; &::focus{ background-color:transparent}};
  };
  & .fld_radio{ padding-right:5px; margin-top:-3px; margin-bottom:-3px };
  & .fld_checkbox{ padding-top:3px; padding-bottom:3px; padding-right:5px };
  & .fld_switch{ margin-left:-8px; margin-top:-6px; margin-bottom:-6px };
  & .fld_phone{  
    border-bottom:1px solid #d5d5d5;
    & input { padding-left:35px !important }; & input, .flag-dropdown{ background:none !important };
    & .selected-flag{ padding-left:1px !important; background:none !important }; 
  };

  .google_autocomplete{ width:100%; 
    & .MuiInput-root::before{ border-color:#d5d5d5 };
    & .MuiInput-input{ padding:7px 0 8px !important } 
  }

  & .MuiInput-root:not(.Mui-error)::before{ border-color:#d5d5d5 };

  & .autocomplete{ 
     width:100%;
    &.icn .MuiInput-root{ padding-left:24px };
    & .MuiInput-root{
        /*&::before{ border-color:#d5d5d5 };*/
        & .MuiInput-input{ padding:7px 4px 8px 0px };
    };
    & .MuiAutocomplete-tag{ 
      margin:5px 5px 4px 0px; height:26px; background:#33B0F2; color:#fff; 
      & svg{ color:#fff; font-size:18px } 
    };
  }

 /* &.err .MuiInput-root::before{ border-color:#d00 }; */

  & .hint{ margin-left:0 };
  & .Mui-error1{ color:#d00 !important; &::after{ border-bottom-color:#d00 !important }; }
  & .MuiFormLabel-asterisk.Mui-error{ color:#d00 !important };
  & .flderr{ 
    & .google_autocomplete .MuiInput-root::before, .fld_select::before{ border-color:#d00 }
    & .fld_phone{ border-bottom-color:#d00 }
    & .MuiCheckbox-root,.lbl-checkbox{ color:#d00 }
  }
  & .button_container{ flex-direction:row; justify-content:space-between; display:flex; align-items:center };
}

.box_sociallogin{
  gap:10px;
  & .btn{ 
    height:40px; marginTop:0px !important; border:none; color:#fff; font-size:14px; border-radius:4px; justify-content:flex-start; gap:8px; padding-left: 10px; padding-right: 10px;
    & .dv{ height:37px; width: 1px; margin-right:4px; background-color:rgba(0,0,0,0.15) };
    & .material-icons{ font-size:24px };
    & svg{ font-size:24px };
    &:hover{ background-color:#333; & .dv{ background-color:rgba(100,100,100,0.4)}}
  }
  @media (min-width:600px){ 
    &{ flex-direction:column; flex:1; justify-content:center; display:flex};
    &.d-row{ flex-direction:row; } 
  };
}

.item-list{
  flex-direction:column; width:100%;
  & .hdr{  position: relative; top: 0; left:0; width:100%; background:#FFF; z-index:10; border-bottom:1px solid #eee; &.stk{ position:sticky; } 
    & .srch{ 
      position:relative; padding:7px 20px 7px 10px; background: transparent;width:100%;
      & .MuiFormControl-root{
        width:100%; & .fa{ margin-right:6px; margin-left:-1px; color:#888 };
        & .MuiInputBase-root{ height:36px; border-radius:18px; background:#f5f5f5; };
        & fieldset{ border-color:rgba(0, 0, 0, 0.01) !important }
      };
      &.mb{ 
        padding:3px; background:#f5f5f5;
        & fieldset{ border-color: transparent !important } 
      }
    }
  }
  & .slider{
    display:flex; width:100%; overflow:auto; position:relative; scrollbar-width:none;
    &::-webkit-scrollbar{ display:none }; &:-ms-overflow-style{ display:none };
    & > div{ left:0; top:0; display:flex; position:absolute; align-items:flex-start; width:100% }
  };
  & .ldr{ flex:1; align-items:center; justify-content:center; text-Align:center; min-height:30px; };
  & .cntr{ position:relative; overflow-y: auto; &.hdn{ overflow-y:hidden  } };
  & .btn-act{ 
    line-height:24px; letter-spacing:0; min-width:36px; height:32px;
    & .fa{ font-size:16px !important; margin-left:1px };
    & .MuiLoadingButton-loadingIndicator{ left:11px };
    &.icn{  & .MuiLoadingButton-loadingIndicator{ left: 17px; }  };
  }
}

.photo_viewer {
  background:rgba(0,0,0,0.9); position: fixed; z-index: 1111; left:0; bottom: 0; width: 100%;
  & .close{ position: absolute; z-index: 111; color:#ddd; font-size:27px; top:0; left:20px; width:50px; height:50px; };
  & .container{ margin: 40px; min-height:200px; background-size:contain; display:flex; background-repeat:no-repeat; background-position:center; justify-content: center;
  };
  & .loader, .cntr{ position:relative; display:flex; flex:1; justify-content:center; align-items:center };
  & .cntr{ justify-content:space-between; position:absolute; top:4px; left:50%; margin-left:-170px; width:340px; zIndex:10; & .cnr{ color:#FFF; font-size:14px };
    & .lft,.rgt{ cursor:pointer; color:#33B0F2; padding:2px 15px; font-size:28px; &.dsb{ color:#555; cursor:text}}
  };
  & .container.mp{ margin-top:50px; & .blk{ display:flex; width: 300px; height: 100%; cursor:pointer; } };
  & .actn{ position:absolute; z-index: 111; top:0; right:20px; display:flex; justify-content:center; align-items:center; color:#eee; font-style:italic; font-size:13.5px; width:50px; height:50px; color: #FFF;
    & > button{ color: #fff; font-size: 20px; width: 40px; height: 40px; };
  };
  @media (max-width:600px){
    & .close{ left:10px }; & .actn{ right:10px; & > button{ width:30px } }; 
    & .container{ margin:20px; margin-top:50px; background-position:center;};
    & .cntr{ margin-left:-90px; width:180px;  }
  };
}

.slider_box{ position:relative; & .slick-slide > div{ display:flex };
  & .cntr{ position:absolute; z-index:10; background-color:rgba(0,0,0,0.8); color:#FFF; font-size:12px; line-height:14px; padding:6px 9px; border-radius:20px; top:15px; right:15px };
  & .islider{ width:100% }
}

.sidebar_box{
  color:#333; display:flex; flex-direction:column; margin-bottom:15px;
  & .background{ transition: all 200ms ease-in; height:100px; background-color:#eee; background-repeat: no-repeat; background-position: center; background-size: cover; position: relative; margin-bottom:-57px;
      &::before{ content:" "; position: absolute; left: 0; top: 0; width: 100%; height: 100% };
  };
  & .content{ 
    display:flex; align-items:center; flex-direction: column;
    & .photo{ position:relative; border:1px solid #e5e5e5; padding:1px; border-radius:50%; background:#FFF; display:flex };
  };
  & .upload{
    cursor:pointer; position:absolute; background:rgba(0,0,0,0.6); border-radius:50%; width:30px; height:30px;z-index:1; font-size:16px;justify-content:center; align-items:center; color:#FFF; right:50%; top:50%; margin-top:-16px; margin-right:-16px;
    &:hover{ background-color: $primaryBgColor }; 
    &.rt{ right:15px;top:15px; border-radius:0 0 0 3px;  };
    &.lf{ right:0px;bottom:0px; top:auto; left:auto; margin-right:8px;  }
  }
}

.box_photouploader{
  width:360px; max-width:100%;
  & .container{
      position: relative;
      & .loader{  top: 0; left: 0; bottom: 0; right: 0; position: absolute; display: flex; align-items: center; justify-content:center; background:rgba(255,255,255,1); z-index:10;
        & .per{ padding-left:10px; width:100px; text-align:center; font-size:17px; font-weight:bold; line-height:20px; margin-top:-10px; margin-left:-50px; left:50%; top:50%; position:absolute; zIndex:1 };
        & .crl{ width:140px; height:140px; border-radius:50%; border:11px solid #ddd; margin-top:-70px; margin-left:-70px; left:50%; top:50%; position:absolute; z-index:0 }
      }
  };
  & .btns{ display:flex; gap:15px;
    & .browse_btn{ 
      flex: 1; border:2px dashed #e0e0e0; color:#777; height:150px; display:flex; justify-content:center; align-items:center; flex-direction:column; gap:5px; cursor:pointer; position:relative; 
      & input{ width: 100%; height: 100%; position: absolute; z-index:1; left:0; top:0; opacity:0; cursor:pointer }
    }
  };
  & .camera-box{ background:#000;  position: relative; z-index: 999; 
    & .cam-cntr{ position: absolute; left: 0; right: 0; bottom: 15px;
      display:flex; justify-content: space-between; gap: 15px; align-items:center;
      & div{  width: 60px; margin-top: 3px; display: flex; justify-content: center; & i{ cursor: pointer; width: 36px; height: 36px; display: flex; background:rgba(0,0,0,0.3); justify-content: center; align-items: center; color: #eee; font-size: 18px;border-radius: 50%; }
      };
      & .capture{ border-radius:50%; border:4px solid #FFF; cursor: pointer; background:rgba(0,0,0,0.3); padding:4px; & span{ width:42px; height:42px; display: flex; border-radius:50%; background: #229fe1;} }
    };
  };
  &.cam .alert{ position: absolute; z-index: 99999; left: 10px; right: 10px; margin-top: 10px; };
   @media (max-width:600px){ & .btns{ flex-direction:column; height:250px; } };
 
}

.dialog_box{ 
  & .MuiDialogActions-root{ background-color:#e5e5e5 };
  & .MuiDialog-paperScrollPaper{ background-color:#FFF; color:#444 };
  & .modal-title{ 
    border-bottom:1px solid #ddd; padding:12px 15px; line-height:22px; display:flex; flex-direction:row; position:relative; justify-content:flex-left; align-items:center; gap:8px;
    & .main{ whiteSpace:nowrap; overflow:hidden; text-overflow:ellipsis; padding-right:0 }
  };
  &.cb .modal-title .main{ padding-right:50px };
  & .modal-close{ position:absolute; z-index:10; font-size:18px; color:rgba(0,0,0,0.4); width: 36px; height:36px; right:6px; top:5px };

}

.alert{ padding:4px 10px; & .MuiAlert-message{ display: flex };
  & .MuiAlertTitle-root{ font-size:14px; line-height:14px; text-transform:capitalize; margin-top:3px; font-weight:600; }
  & .message{ font-weight:500; font-size:14px; line-height: 15px; padding-top:1px; align-self:center }
  &.st{ & .MuiAlert-message{ display:block }; & .message{ font-weight:400; } }
}

.sidenave_mobile{ 
  position:fixed; padding-left: $sideNavWidth; top: 0; left: 0; bottom: 0;right: 0; width:100vw;
  background:rgba(0, 0, 0, 0.54); z-index:-1;
  @media (min-width:900px){ &{ display:none } };
  & .cls{ width: $topBarHeight; height: $topBarHeight; cursor:pointer; display:flex; justify-content:center; align-items:center;
    & .icon{ font-size:24px;color:#FFF; cursor:pointer } 
  }
}

.user-menu-btn{ 
  padding:1px; border:1px solid #FFF; border-radius:50%; width:40px; height:40px; cursor:pointer;margin-left:8px;
  & > div{ background:#FFF };
  @media (max-width:600px){ &{ margin-right:6px } };
}
.user-menu .MuiPaper-root{  
  margin-top:7px; overflow:visible; filter:drop-shadow(0px 2px 8px rgba(0,0,0,0.32));
  & ul{ padding-top:4px !important; padding-bottom:4px !important };
  & hr{ margin-top:4px !important; margin-bottom:4px !important };
  & li{ min-height:auto !important; & i{ font-size:16px; margin-right:8px; margin-top:-1px} };
  & .MuiAvatar-root{ width: 32px; height: 32px; margin-left: 1px; margin-right: 1px }; 
  &::before{ content: ""; display:block; position:absolute; top: 0; right: 14px; width: 10px; height: 10px; transform:translateY(-50%) rotate(45deg); z-index: 0; background-color:#FFF }
}

.share-box{  display:flex; flex-wrap:wrap; gap:14px; padding:13px 0px 13px 13px; max-width:243px  }
.ad_box{ max-width:100%; & div.ac{ overflow:hidden; max-width:100%; margin-top:-2px } }

.notify_item{
  padding:10px 15px; gap:11px; border:0;
  & .pht{ cursor:pointer; display:flex; width:44px; height:44px; padding:1px; border-radius:50%; };
  & .dtl{ display:flex; flex:1; flex-direction:column; justify-content: center;
    & p{ 
      margin-bottom: 4px; line-height: 16px; font-weight:13px ;
      & span, .a{ padding-left:5px; cursor:pointer };
      &.bld{ color:#111; cursor:pointer};
    };
    & .tm{ 
      font-size:12px;color:#888; flex-direction:row; gap:4px; display:flex; align-items: center;
      & .fa{ font-size:13px } 
    }
  }
}

.blogs-item{ border-radius:1px; overflow:hidden; margin:0px; box-shadow:0 0 1px #eee inset; position: relative; background-color:#FFF; display: flex; height: 100%; flex-direction: column;
  & .hdn{ display:none; }
  & .img{ position: relative; background-position:center; background-size:cover; background-repeat: no-repeat; border-bottom:1px solid #f5f5f5; cursor:pointer;
    & .ct{ position:absolute; right:0; bottom:0; color:#FFF; padding:3px 8px; font-size: 13px; line-height: 15px; background:rgba(0, 0, 0,0.7); border-radius:3px 0 0 0; }
  };
  & .inf{ padding:12px 15px;
    & a{ text-decoration: none; };
    & .ttl{ line-height:16px; };
    & .ttl a, & .dsc, & .tp{ overflow:hidden; text-overflow:ellipsis; display:-webkit-box; -webkit-line-clamp:1; -webkit-box-orient:vertical };
    & .ttl a{ font-size:13.5px; font-weight:500; -webkit-line-clamp:2; }
    & .dsc{ margin-top:5px; -webkit-line-clamp:3;  font-size:13.5px; line-height:15px; font-weight:400; };
    & .tp{ font-size:13px; margin-top:6px;  line-height:16px; color:#666; & .d{ font-size: 22px; line-height: 1px; padding: 0 4px; position: relative; top: -3px;} & .dt{ color:#999; text-transform:uppercase; } }
  }
  &.bi-view{ 
    & .inf{ 
      padding:10px 12px; & .dsc,.tp{ margin-top:4px; }
      & .ttl{ & a{ font-weight: normal; font-size:13.5px} }
      & .tp{ & .d,.us,.ds{ display:none } }
    }
  }
  &.bi-d{ background:#eee; };
  &.bi-p{ background:yellow; };
  &.bi-dashboard{ border-radius:4px; overflow:hidden; };
  &.bi-profile{ border:1px solid #e5e5e5; box-shadow: none; }
  
  @media(max-width:600px){
    & .inf{ padding:10px 12px; & .dsc,.tp{ margin-top:4px; } 
      & .ttl{  line-height:16px; & a{ font-weight: normal; font-size:13.5px} } 
      & .dsc{ -webkit-line-clamp:2; font-size:13px; line-height:14px; }
      & .tp{ & .d,.us,.ds{ display:none } }  
    }
  }
  @media(max-width:900px){
    &.bi-dashboard{ width:170px; & .dsc{ -webkit-line-clamp:3; } }
  }

  @media(max-width:420px){ 
    & .hdn{ display:block; } 
    & .inf .ttl{ & a{ -webkit-line-clamp:3; } }
    &.bi-dashboard .inf .ttl{ & a{ -webkit-line-clamp:2; } } 
  } 
}
